const bestPrice = (cinemaWorldPrice, filmWorldPrice) => {
  if (cinemaWorldPrice < filmWorldPrice) {
    return "Cinema World";
  } else if (filmWorldPrice === undefined) {
    return "Cinema World";
  } else if (cinemaWorldPrice === undefined) {
    return "Film World";
  } else {
    return "Film World";
  }
};

export default bestPrice;
